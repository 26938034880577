import React from "react";
import './Home.css';
import { Link } from "react-router-dom";
import Danielle from '/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/unnamed.jpg';
import Daniel from '/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/unnamed-6.jpg'; // 
import Group from '/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/unnamed-1.jpg'
import photo1 from '/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/unnamed-2.jpg'
import photo2 from '/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/unnamed-4.jpg'


export const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <div className="photo-wrapper">
        
        <img src={Group} alt="Group" className="responsive-photo" />
        <p className="blurb"><span className="highlight">Prospect Bark Service</span> provides full-service pet care conveniently located near the heart of Brooklyn, Prospect Park. Together Danielle and Daniel have decades of animal care experience providing communicative, personalized solutions to enlarge your pet's world.</p>
      </div>

      <div className="photo-wrapper">
        <img src={Danielle} alt="Danielle" className="responsive-photo" />
        <p className="blurb">
          <span className="highlight">Danielle</span> is dedicated to providing loving, conscientious care for the animals of Brooklyn and has operated an independent dog walking and sitting business for many years. She specializes in running high-energy breeds, puppy care, special needs dogs, and exotic animals including rodents, reptiles, and rabbits. Danielle is a multi-instrumentalist musician, cyclist, and backpacker who loves live music, cooking, and the outdoors.
        </p>
      </div>

      <div className="photo-wrapper">
        <img src={Daniel} alt="Daniel" className="responsive-photo" />
        <p className="blurb">
          <span className="highlight">Daniel</span> is a professional biologist who spent his 20’s with the National Park Service from the East Coast up to Alaska. Trained in animal behavior, Daniel takes pride in working with challenging pets. He specializes in socializing anxious pets and particularly enjoys new rescues, dogs with a challenging background, pandemic pups, working dogs, and reactive large dogs. He is comfortable handling less common pets of any kind including birds, fishes, and amphibians. Daniel enjoys biking, hiking, and gardening when he isn't playing with your pet.
        </p>
      </div>

      <div className="photo-wrapper">
        <img src={photo1} className="responsive-photo" />
        <div>
          <p><Link to="/services" className="contact-link">Check out the services we offer!</Link> </p>
          </div>
    
    </div>
    </div>
  );
};
