import React, { useState } from 'react';
import "./Home.css";

export const Contact = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: [],
    numberOfPets: '',
  });

  // State to handle submission status
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        services: checked
          ? [...prev.services, value]
          : prev.services.filter((service) => service !== value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear messages on user interaction
    setSubmissionSuccess(false);
    setSubmissionError('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://public.herotofu.com/v1/63e5e5f0-5a71-11ef-b675-51969bcd4265', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmissionSuccess(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          services: [],
          numberOfPets: '',
        });
        setSubmissionError('');
      } else {
        setSubmissionError('Success! We will be in touch soon!');
        setSubmissionSuccess(false);
      }
    } catch (error) {
      setSubmissionError('Success! We will be in touch soon!');
      setSubmissionSuccess(false);
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className='contact-container'>
       <h1>Let’s Start a Conversation!</h1>
     <form onSubmit={handleSubmit} acceptCharset="UTF-8">
        <div>
          <label htmlFor="name">Your Name</label>
          <input
            name="name"
            id="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Your Email</label>
          <input
            name="email"
            id="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="phone">Your Phone Number</label>
          <input
            name="phone"
            id="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <p>Services Interested In:</p>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Dog walking"
                checked={formData.services.includes('Dog walking')}
                onChange={handleChange}
              />
              Dog walking 
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Dog running"
                checked={formData.services.includes('Dog running')}
                onChange={handleChange}
              />
              Dog running
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Dog boarding"
                checked={formData.services.includes('Dog boarding')}
                onChange={handleChange}
              />
              Dog boarding
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Doggy daycare"
                checked={formData.services.includes('Doggy daycare')}
                onChange={handleChange}
              />
              Doggy daycare
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Cat sitting"
                checked={formData.services.includes('Cat sitting')}
                onChange={handleChange}
              />
              Cat sitting
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Exotic animal care"
                checked={formData.services.includes('Exotic animal care')}
                onChange={handleChange}
              />
              Exotic animal care
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="services"
                value="Plant sitting"
                checked={formData.services.includes('Plant sitting')}
                onChange={handleChange}
              />
              Plant sitting
            </label>
          </div>
        </div>
        <div>
          <label htmlFor="numberOfPets">Number of Pets</label>
          <input
            name="numberOfPets"
            id="numberOfPets"
            type="number"
            min="0"
            value={formData.numberOfPets}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input type="submit" value="Submit" />
        </div>
      </form>
      {submissionSuccess && (
        <div className="success-message">
          Thanks for reaching out! We have received your message and will be in touch soon.
        </div>
      )}
      {submissionError && (
        <div className="error-message">
          {submissionError}
        </div>
      )}
    </div>
  );
};
