import React from "react";
import { Link } from "react-router-dom"; // Import Link
import './Home.css';

export const Services = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <div className="service-item">
        <p><span>Dog Walking:</span> <br />Small group walks, bespoke packs to socialize and relieve anxiety, and bring a sense of security to your pup.</p>
      </div>
 
      <div className="service-item">
        <p><span>Dog Running:</span> <br />30-60 minute neighborhood or park runs to exercise your dog.
        
        </p>
       
      </div>
      <div className="service-item">
        <p><span>Dog Boarding:</span> <br />Comfortable and caring overnight stays for your dog.</p>
      </div>
      <div className="service-item">
        <p><span>Doggy Daycare:</span> <br />Fun and engaging activities for your dog during the day.</p>
      </div>
      <div className="service-item">
        <p><span>Cat Sitting:</span> <br />Care and companionship for your feline friends while you are away.</p>
      </div>
      <div className="service-item">
        <p><span>Exotic Animal Care:</span> <br />Specialized care for your unique pets.</p>
      </div>
      <div className="service-item">
        <p><span>Plant Sitting:</span> <br />Keeping your plants healthy and thriving while you're gone.</p>
      </div>
      <div className="contact-link">
      <p><Link to="/contact">Contact us now to find out more!</Link></p>
<br></br>
      </div>
    </div>
  );
};
