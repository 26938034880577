import React from "react";
import './Home.css'
import ParkLogo from "/Users/jeremy/ Code/Prospect_Bark_Service/React-responsive-navbar/src/components/Photos/PBSlogoXparentback.png"; 

export const Home = () => {
  return (
    <div className="home-logo">
      
      <img src={ParkLogo} alt="Park Logo" />
    </div>
  );
};
